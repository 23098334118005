import { useState, useEffect } from "react";
//This is a helper function that is used to set the height and width of the table, keeping it from needing a scroll bar.
//This is to allow a better resolution and display on both desktop and mobile devices by getting
//the windows display size and using it instead of a hard coded value.
export function useWindowResize() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const listener = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener("resize", listener);
        return () => {
            window.removeEventListener("resize", listener);
        };
    }, []);

    return {
        width,
        height,
    };
}
