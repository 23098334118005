import React, { useState, useEffect } from "react";
//Amplify configuration and imports here.
//import Amplify from "aws-amplify";
import {
    AmplifyAuthenticator,
    AmplifySignIn,
    AmplifyForgotPassword,
} from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
//import awsconfig from "./aws-exports";

import { usePromiseTracker } from "react-promise-tracker";
import LoadingOverlay from "react-loading-overlay";
import RingLoader from "react-spinners/RingLoader";

import DisplayTable from "./components/FrontEnd_Table/DisplayTable";
import CustomNavbar from "./components/Navbar/CustomNavbar";

import "./styling/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
//This configures the application to use the aws-amplify configuration that's imported above.
import Amplify from "@aws-amplify/core";
import { Auth } from '@aws-amplify/auth'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)
Auth.configure(awsconfig)

const App = () => {
    //Tracks the authenticated state (if logged in or not)
    const [authState, setAuthState] = useState();
    //Sets the user from the login status
    const [user, setUser] = useState();
    //A tracker created that initiates a loading circle when a mass deletion is done from the Table.
    const { promiseInProgress } = usePromiseTracker();

    //useEffect is a promise based hook (added in a recent update to reactjs, read here for more info "https://reactjs.org/docs/hooks-intro.html")
    //The effect kicks in when the login is done, at this point it sets the authState, and the user data from the amplify configuration.
    useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData);
        });
    }, []);
    //This is essentially a if else statement in react.
    //The first checkes if the user is signed in, if not, it executes the second block. [basically if true ? ( this ) : (otherwise this)]
    return authState === AuthState.SignedIn && user ? (
        // If you're not familiar with reactjs, I highly recommend reading the docs on reactjs.org, and checking out Codevolutions youtube channel.
        <div className="App">
            <LoadingOverlay
                active={promiseInProgress}
                spinner={<RingLoader size={150} color={"#0095FF"} />}
                text="Deleting selected rows...">
                <CustomNavbar username={user.username} />
                <div id="modal-root"></div>
                {/* This is the loading overlay wrapper that is used to track the buffer of a mass deletion from the table 
            it's only purpose is providing a nice overlay to let users know that rows are being deleted.*/}

                {/* Calls the table component that displays all the data */}
                <DisplayTable userInfo={user.signInUserSession.idToken.jwtToken} />
            </LoadingOverlay>
        </div>
    ) : (
        //If a user isn't logged in, it displays the login component in the AmplifyAuthenticator module.
        //The reason this is built this way instead of just using the default, is for the sake of customization, like removing the option for a user to create an account.
        <div className="authenticator-wrapper">
            <AmplifyAuthenticator>
                <AmplifySignIn
                    headerText=""
                    slot="sign-in"
                    hideSignUp></AmplifySignIn>
                <AmplifyForgotPassword
                    headerText="Reset your password."
                    slot="forgot-password"></AmplifyForgotPassword>
            </AmplifyAuthenticator>
        </div>
    );
};

export default App;
