// This is the tab based control system for the facial verification modal
// it's purpose is to provide a tabbed display to allow users to select
// between local and remote image scanning

import React, { useState } from "react";

import { Container, Tabs, Tab } from "react-bootstrap";
import DisplayTable from "./SupplyChainRecords";
import SupplyChainRecords from "./SupplyChainRecords";
//import FacialRecognitionRemote from "./FacialRecognitionRemote";
import "bootstrap/dist/css/bootstrap.min.css";
function SupplyChainContainer() {
    const [key, setKey] = useState("local");

    return (
        
            <SupplyChainRecords />

    );
}

export default SupplyChainContainer;
